.usdPage {
    height: 100%;
    background: #363a48;
    background: url('images/usd_background.jpg') no-repeat center center fixed;
    background-size: cover;
    padding: 25px;
    position: relative;
    overflow: auto !important;
    background-position: center !important;

    .usdHeadWrap {
        margin: 0 auto;
        margin-bottom: 50px;
        .usdHeadBox {
            display: flex;
            padding: 25px;
        }
        .usdHeadLogo {
            display: inline-block;
            align-self: center;
            margin-right: 25px;
            min-width: 96px;
            height: 96px;
            background: url('images/izum_logo.svg') no-repeat center center;
            background-size: cover;
            vertical-align: middle;
        }
        .usdHeadTitle {
            align-self: center;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
        }
    }

    .usdFormWrap {
        width: 70%;
        margin: 0 auto;
        .usdForm {
            padding: 25px 25px 50px 25px;
            text-align: center;
        }
        .UsdCheckbox {
            padding: 25px 0;
            .MuiFormControlLabel-label {
                text-align: start;
            }
        }
    }
}

.usdPage.opacityBg {
    position: relative;
}

#iframe {
    width: 100%;
    height: 100%;
}
